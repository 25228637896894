@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap');

* {
  box-sizing: border-box;
  font-family: Montserrat;
}

html {
  font-family: Montserrat;
}

body {
  margin: 0;
  background-color: black;
  color: white;
}

