@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap);
* {
  box-sizing: border-box;
  font-family: Montserrat;
}

html {
  font-family: Montserrat;
}

body {
  margin: 0;
  background-color: black;
  color: white;
}


.app-container{
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items:center;
  align-content:center;
  min-height: 80vh;
  flex: 1 0 auto;
}

.footer{
  display: flex;
  flex-direction: column;
  padding-top: 1em;
  flex: 0 1;
}


.animate__slideLeft{
    animation-name: moveInLeft;
    animation-duration: 2.5s;
}

.animate__fadeIn{
    animation-name: fadeIn;
    animation-duration: 1.5s;
}

.animate__fadeInLogo{
    animation-name: fadeIn;
    animation-duration: 5s;
}

.animate__fadeInPage{
    animation-name:fadeIn;
    animation-duration: 0.8s;
}

/* Ripple effect */
.button__ripple {
    background-position: center;
    transition: background 0.8s;
}
.button__ripple:hover {
    background: gray radial-gradient(circle, transparent 1%, #47a7f5 1%) center/15000%;
}
.button__ripple:active {
    background-color: #6eb9f7;
    background-size: 100%;
    transition: background 0s;
  }

@keyframes moveInLeft {
    0%{
        opacity:0;
        transform: translateX(-100px);
    }
    100%{
        opacity:1;
        transform: translate(0);
    }
}

@keyframes fadeIn{
    0%{
        opacity:0;
    }
    100%{
        opacity:1;
    }
}
.logo {
    width:160px;
    height: 160px;
}

.logo-container{
    display: flex;
    background-color: white;
    padding-bottom: 1em;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}


.nav {
    background-color: white;
    color: black;
    padding-bottom: 0.5em;
  }
  
  .nav ul {
    display: flex;
    justify-content:center;
    align-content:center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
  }
  
  .nav li {
    display:inline-flex;
    justify-content: center;
    padding-left: 2em;
    padding-right: 2em;
  }
  
  .nav a {
    color: black;
    text-decoration: none;
    padding: 0.3em;
  }
  
  .nav li:hover {
    background-color:lightgray;
    border-radius: 10px;
  }

  .hamburger {
    position: absolute;
    left: 30px;
    top: 30px;
    width: 40px;
    height: 40px;
    display: none;
}

  @media all and (max-width: 889px){
    .nav ul {
      display: none;
    }
    .nav li {
        display:inline-flex;
        border-radius: 10px;
        border-style: solid;
        border-color: black;
        padding-left: 2em;
        padding-right: 2em;
        width: 150px;
      }
    .hamburger{
      display:inline-flex;
    }
  }

  @media all and (min-width: 890px){
    .nav ul {
      display:flex;
    }
  }
.general {
    display: flex;
    align-content: flex-start;
    flex-direction: column;
    background-color: black;
    align-items: center;
    color: white;
    padding-bottom: 60px;
    max-width: 900px;
}

.general p {
    padding: 0.25em;
}

.general h2{
    padding: 1em;
}

.general a {
    color: white;
}

.general img{
    width: 100%;
    height: auto;
    border-radius: 40px;
    padding: 0.5em;
}

.general__button {
    border-style: solid;
    border-color:white;
    border-radius: 40px;
    font-size: 25px;
    padding: 0.4em;
    text-align: center;
    background-color:white;
}

.general__button a {
    padding-left: 15px;
    padding-right: 15px;
    color: black;
    text-decoration: none;
}

@media all and (max-width: 736px){
    .general {
        display: flex;
        align-content: flex-start;
        flex-direction: column;
        background-color: black;
        align-items: center;
        color: white;
        padding-bottom: 60px;
        max-width: 95%;
    }
}
.main-footer {
    display: flex;
    flex-direction: row;
    background-color: white;
    color: black;
    padding: 1em;
    width: 100%;
    height: 60px;
    justify-content: space-between;
}

.main-footer li{
    display: inline;
    text-align: end;
    padding: 1em;
}

.main-footer img{
    width: 30px;
    height: 30px;
}

.main-footer__title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.main-footer__social {
    display:flex;
    align-items: center;
    justify-content: flex-end;
}

.main-footer__button {
    border-style: solid;
    border-color:white;
    border-radius: 40px;
    font-size: 25px;
    padding: 0.4em;
    text-align: center;
    background-color:white;
}

.main-footer__button a {
    color: black;
    text-decoration: none;
}

.contact-page hr{
    width: 100%;
    padding: 1em;
    visibility: hidden;
}

.contact-page th{
    padding-right: 0.5em;
}

.contact-page form{
    display: flex;
    flex-direction: column;
}

.contact-page img{
    width: 60%;
    height: auto;
}

.contact-page form h2{
    justify-content: flex-start;
    align-items: center;
    align-content: center;
}

.contact-page form label{
    padding-top: 0.7em;
    font-size: 15px;
}

.contact-page form input{
    border-radius: 10px;
    font-size: 20px;
}

.contact-page form textarea{
    border-radius: 10px;
    font-size: 15px;
}

.contact-page form select{
    font-family: Montserrat;
    border-radius: 10px;
    font-size: 20px;
    width: 100%;
    height: 40px;
    align-content: center;
}

@media all and (max-width: 850px){
    .contact-page img{
        width: 90%;
        height: auto;
    }
}

.main-page__green {
    color: limegreen;
}

.main-page__main-container{
    width: 90%;
}

.welcome-box {
    width: 100%;
    height: auto;
    display: flex;
    background-image: url(/static/media/dentedzebra_standing.3270f8d9.png);
    background-blend-mode:multiply;
    background-size:cover;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    padding-bottom: 4.8em;
}

.welcome-box button{
    display: flex;
    justify-content: center;
    width: 100%;
}

@media all and (max-width: 736px){
    .welcome-box {
        width: 95%;
        height: auto;
        display: flex;
        background-image: none;
        background-blend-mode:multiply;
        background-size:cover;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding-bottom: 0;
    }
}

@media all and (min-width:898px){
    .welcome-box {
        width: 100%;
        height: auto;
        display: flex;
        background-image: url(/static/media/dentedzebra_standing.3270f8d9.png);
        background-blend-mode:multiply;
        background-size:cover;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-end;
        padding-bottom: 9em;
    }
}

.repair__list{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.about img{
    width: 400px;
    height: 300px;
}

.team-page img{
    width: 80%;
    height: auto;
}

.team-page td{
    width: 50%;
}

.team-page hr{
    margin-left: 25%;
    margin-right: 25%;
    width: 70%;
    color: lightgray;
    
}
.austincraven-page em{
    font-weight: bolder;
}

.austincraven-page p{
    font-size: 20px;
}

