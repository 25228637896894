.main-footer {
    display: flex;
    flex-direction: row;
    background-color: white;
    color: black;
    padding: 1em;
    width: 100%;
    height: 60px;
    justify-content: space-between;
}

.main-footer li{
    display: inline;
    text-align: end;
    padding: 1em;
}

.main-footer img{
    width: 30px;
    height: 30px;
}

.main-footer__title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.main-footer__social {
    display:flex;
    align-items: center;
    justify-content: flex-end;
}

.main-footer__button {
    border-style: solid;
    border-color:white;
    border-radius: 40px;
    font-size: 25px;
    padding: 0.4em;
    text-align: center;
    background-color:white;
}

.main-footer__button a {
    color: black;
    text-decoration: none;
}
