.logo {
    width:160px;
    height: 160px;
}

.logo-container{
    display: flex;
    background-color: white;
    padding-bottom: 1em;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

