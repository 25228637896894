.animate__slideLeft{
    animation-name: moveInLeft;
    animation-duration: 2.5s;
}

.animate__fadeIn{
    animation-name: fadeIn;
    animation-duration: 1.5s;
}

.animate__fadeInLogo{
    animation-name: fadeIn;
    animation-duration: 5s;
}

.animate__fadeInPage{
    animation-name:fadeIn;
    animation-duration: 0.8s;
}

/* Ripple effect */
.button__ripple {
    background-position: center;
    transition: background 0.8s;
}
.button__ripple:hover {
    background: gray radial-gradient(circle, transparent 1%, #47a7f5 1%) center/15000%;
}
.button__ripple:active {
    background-color: #6eb9f7;
    background-size: 100%;
    transition: background 0s;
  }

@keyframes moveInLeft {
    0%{
        opacity:0;
        transform: translateX(-100px);
    }
    100%{
        opacity:1;
        transform: translate(0);
    }
}

@keyframes fadeIn{
    0%{
        opacity:0;
    }
    100%{
        opacity:1;
    }
}