.app-container{
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items:center;
  align-content:center;
  min-height: 80vh;
  flex: 1 0 auto;
}

.footer{
  display: flex;
  flex-direction: column;
  padding-top: 1em;
  flex: 0;
}

