.nav {
    background-color: white;
    color: black;
    padding-bottom: 0.5em;
  }
  
  .nav ul {
    display: flex;
    justify-content:center;
    align-content:center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
  }
  
  .nav li {
    display:inline-flex;
    justify-content: center;
    padding-left: 2em;
    padding-right: 2em;
  }
  
  .nav a {
    color: black;
    text-decoration: none;
    padding: 0.3em;
  }
  
  .nav li:hover {
    background-color:lightgray;
    border-radius: 10px;
  }

  .hamburger {
    position: absolute;
    left: 30px;
    top: 30px;
    width: 40px;
    height: 40px;
    display: none;
}

  @media all and (max-width: 889px){
    .nav ul {
      display: none;
    }
    .nav li {
        display:inline-flex;
        border-radius: 10px;
        border-style: solid;
        border-color: black;
        padding-left: 2em;
        padding-right: 2em;
        width: 150px;
      }
    .hamburger{
      display:inline-flex;
    }
  }

  @media all and (min-width: 890px){
    .nav ul {
      display:flex;
    }
  }