.contact-page hr{
    width: 100%;
    padding: 1em;
    visibility: hidden;
}

.contact-page th{
    padding-right: 0.5em;
}

.contact-page form{
    display: flex;
    flex-direction: column;
}

.contact-page img{
    width: 60%;
    height: auto;
}

.contact-page form h2{
    justify-content: flex-start;
    align-items: center;
    align-content: center;
}

.contact-page form label{
    padding-top: 0.7em;
    font-size: 15px;
}

.contact-page form input{
    border-radius: 10px;
    font-size: 20px;
}

.contact-page form textarea{
    border-radius: 10px;
    font-size: 15px;
}

.contact-page form select{
    font-family: Montserrat;
    border-radius: 10px;
    font-size: 20px;
    width: 100%;
    height: 40px;
    align-content: center;
}

@media all and (max-width: 850px){
    .contact-page img{
        width: 90%;
        height: auto;
    }
}
